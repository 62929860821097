.gallery {
  display: flex;
  justify-content: center;
}

.ant-image-mask {
  margin: 2rem;
}

.imageItem {
  display: flex;
  flex: 1;
  border-radius: 10px;
  padding: 2rem;
}
.highImageItem {
  width: 1304px;
  height: 980px;
}

.carousel,
.ant-image-img.imageItem {
  width: 652px !important;
  height: 490px;
}

@media screen and (max-width: 960px) {
  .carousel,
  .ant-image-img.imageItem {
    width: 359px !important;
    height: 270px;
  }
}
