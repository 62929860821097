/* for images */
.home-container {
  min-height: 95vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  object-fit: contain;
  background-image: var(--background-image);
}
.profile-container {
  text-align: right;
  width: 38%;
}
.ant-image .ant-image-img {
  width: 400px;
  height: 400px;
}
.hero-container {
  display: flex;
  flex-direction: column;
  object-fit: contain;
  content: center;
  width: 58%;
}
.spacer {
  width: 4%;
}

.hero-container > h1 {
  color: var(--white-color);
  font-size: 100px;
}
.hero-container > p {
  margin-top: 8px;
  width: 80%;
  color: var(--white-color);
  font-size: 32px;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .home-container {
    padding-bottom: 5vh;
    padding-top: 5vh;
    flex-direction: column;
  }
  .hero-container > h1 {
    font-size: 70px;
  }
  .hero-container {
    width: 100%;
    text-align: center;
    display: contents;
  }

  .profile-container {
    text-align: center;
    width: 100%;
  }
  .ant-image .ant-image-img {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    margin-top: 20px;
    font-size: 50px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
  .ant-image .ant-image-img {
    width: 90%;
    height: 90%;
  }
}
