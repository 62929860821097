* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
  --black-color: #242424;
  --white-color: #fff;
  --secondary-color: #fca311;
  --background-image: url("../public/images/patternBackground.webp");
}

::-webkit-scrollbar {
  display: none;
}
